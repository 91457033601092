import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Page.module.scss';
import withApp from "../../context/withApp";
import isSecondaryPage from '../../utils/isSecondaryPage';
import ScrollManager from '../ScrollManager/ScrollManager'

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind( this );
    }
    
    componentDidMount(){
        const { app, theme } = this.props;
        app.setUiTheme( null, theme.color );
    }

    shouldComponentUpdate( nextProps ) {
        return nextProps.location.pathname === this.props.location.pathname;
    }

    handleScroll( element, e ) {
        if( this.props.onScroll ) {
            this.props.onScroll( element, e ); 
        }
    }
    
    render(){
        const { 
            isDisabled, 
            theme,
            location,
            isModal
        } = this.props;

        const className = classnames( !isModal ? styles.default : styles.modal, {
            [ styles.disabled ]: !isModal && isDisabled,
            [ styles.scrollBarAlt ]: !isModal && theme.backgroundColor === '#fff',
            [ styles.indent ]: !isModal && location.pathname && isSecondaryPage( location.pathname )
        });

        // console.log(this.props.innerRef);

        return !isModal ? (
            <ScrollManager scrollKey={location.pathname}>
                {({ connectScrollTarget, forwardedRef, ...props }) => 
                    <div className={className} style={{ ...!isModal && theme }} ref={connectScrollTarget} onScroll={ this.handleScroll.bind( null, forwardedRef ) }>
                        { this.props.children }
                    </div>
                }
            </ScrollManager>
        ) : (
            <div className={className}>
                { this.props.children }
            </div>
        );
    }
};

Page.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
    theme: PropTypes.object,
    isDisabled: PropTypes.bool,
};

Page.defaultProps = {
    theme: {
        foreground: '#fff',
        background: '#000'
    },
    isDisabled: false
}

export default withApp( Page );