/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import withApp from '../../context/withApp';

class SEO extends Component {
    componentDidMount(){
        if ( this.props.setPageTitle ) {
            this.props.app.set({ 
                pageTitle: this.props.title
            });
        }
    }

    render() {
        const { 
            description, 
            lang, 
            meta, 
            keywords, 
            title,
            image
         } = this.props;

        return (
            <StaticQuery
                query={graphql`
                    query {
                        site {
                            siteMetadata {
                                title
                                description
                                author
                                image
                                siteUrl
                            }
                        }
                    }
                `}
                render={ ({site}) => {
                    const metaDescription = description || site.siteMetadata.description;
                    const metaImage = site.siteMetadata.siteUrl + ( image || site.siteMetadata.image );

                    const metaData = [
                            {
                                name: `description`,
                                content: metaDescription,
                            },
                            {
                                property: `og:title`,
                                content: title,
                            },
                            {
                                property: `og:description`,
                                content: metaDescription,
                            },
                            {
                                property: `og:type`,
                                content: `website`,
                            },
                            {
                                property: `og:image`,
                                content: metaImage,
                            },
                            {
                                name: `twitter:card`,
                                content: `summary`,
                            },
                            {
                                name: `twitter:creator`,
                                content: site.siteMetadata.author,
                            },
                            {
                                name: `twitter:title`,
                                content: title,
                            },
                            {
                                name: `twitter:description`,
                                content: metaDescription,
                            },
                        ]
                        .concat(
                            keywords.length > 0
                                ? {
                                    name: `keywords`,
                                    content: keywords.join(`, `),
                                }
                                : []
                            )
                        .concat( meta );

                    return (
                        <Helmet>
                            <html lang={lang} />
                            <title itemProp="name" lang="en">{title} | {site.siteMetadata.title}</title>
                            {metaData.map((props, index) => (
                                <meta {...props} key={index}/>
                            ))}
                        </Helmet>
                    )
                }}/>
        )
    }
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    keywords: [],
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
}

export default withApp( SEO );
